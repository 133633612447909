@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

img {
  max-width: 100%;
}

h2 {
  text-transform: uppercase;
  position: relative;
}

h2::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 2px;
  display: block;
  background: #da0303;
  bottom: -15px;
  left: calc(50% - 75px);
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f4f4f4;
}

.main-banner {
  background-image: url("./images/banner.png");
  height: 100vh;
  background-size: cover;
}

.navbar {
  background-color: #fff;
  box-shadow: 0px 0px 2px #878787;
}

.tab-content .card img {
  padding-bottom: 0;
}

.navbar-nav {
  margin-left: auto;
  background-color: #fff;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.carousel-item,
.carousel-inner,
.carousel {
  height: 100%;
}

#home .carousel-caption {
  top: 35%;
  text-align: left;
  color: #313131;
  text-shadow: 0 1px 3px #fff;
  left: 0;
  width: 100%;
}

#home .carousel-control-next,
#home .carousel-control-prev {
  display: none;
}

#home .carousel-indicators [data-bs-target] {
  background-color: #dedede;
}

.header-top {
  background-color: transparent;
  box-shadow: none;
}

.main-menu {
  background-color: transparent;
}

.main-menu .nav-link,
#basic-navbar-nav .nav-link.active {
  color: #fff;
  /* text-shadow: 0 0px 3px black; */
}

#counter>div {
  margin-bottom: 15px;
}

#counter-section {
  height: 400px;
  align-content: center;
  color: #fff;
}

#counter-section h3 {
  font-size: 60px;
}

.navbar-nav li,
.main-menu.navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: 500;
  padding: 15px;
}

#basic-navbar-nav .main-menu.navbar-nav .nav-link {
  padding: 15px 25px;
}

.navbar-nav .nav-link {
  font-size: 15px;
}

/* .navbar-nav .nav-link.active-menu::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #da0303;
  display: block;
  margin: auto;
  margin-top: 4px;
} */

.reduced-nav {
  background-color: #fff;
}

.reduced-nav .navbar-brand img {
  width: auto;
  height: 90px;
  transition: 0.4s;
}

.reduced-nav #basic-navbar-nav .main-menu.navbar-nav .nav-link {
  color: #606060;
  text-shadow: none;
}

.navbar-nav .nav-link.active-menu {
  color: #000;
}

.reduced-nav #basic-navbar-nav .main-menu.navbar-nav .nav-link.active-menu {
  color: #da0303;
}

footer {
  background-color: #101010;
  padding-top: 30px;
}

.foot-content {
  padding-bottom: 30px;
}

footer .col-md-4 {
  margin-bottom: 15px;
}

.insta-section .col-md-4 {
  padding-left: 8px;
  padding-right: 8px;
}

.insta-section img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 100px;
}

.foot-content h4 {
  color: #e2e2e2;
  margin-bottom: 25px;
  text-transform: uppercase;
  display: inline-block;
}

.foot-content p {
  color: #dad8d8;
}

.contact-data h4 {
  margin-bottom: 25px;
  text-transform: uppercase;
  color: #000000;
  display: inline-block;
}

.foot-content h4::after,
.contact-data h4::after {
  text-transform: uppercase;
  background: #da0303;
  content: "";
  margin-top: 15px;
  width: 100px;
  height: 2px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.foot-content h4::after {
  height: 1px;
}

.copyright_section {
  padding: 20px;
  background-color: #000;
  color: #fff;
  font-size: 12px;
}

.subscribe-section input {
  background-color: transparent;
  border: 1px solid #a6a6a6;
  padding: 5px 20px;
  width: 100%;
  margin-bottom: 10px;
}

.subscribe-section button {
  padding: 5px 20px;
  border: 1px solid #a6a6a6;
  background-color: transparent;
  color: #a6a6a6;
  text-transform: uppercase;
  font-weight: 600;
}

.subscribe-section button:hover {
  border-color: #000;
  color: #000;
}

.contact-map {
  /* padding-top: 110px; */
  height: 400px;
  /* padding-bottom: 100px; */
}

.contact-data-container {
  background-color: #101010;
}

.contact-data {
  padding-left: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 525px;
  margin: auto;
  margin-top: -136px;
  z-index: 1;
  position: relative;
}

.content-contact {
  margin: 0;
  padding: 0;
}

.content-contact>li {
  display: flex;
  padding: 1px 0;
}

.content-contact i {
  width: 30px;
  padding-top: 5px;
  flex-shrink: 0;
}

.content-contact a {
  color: #000;
  text-decoration: none;
}

#menu img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#menu .col-md-3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

#menu .title {
  text-transform: uppercase;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 16px;
}

.price {
  font-weight: 600;
  font-size: 18px;
  color: #da0303;
}

#services {
  background-color: #eaeaea;
}

#services .card {
  padding: 20px 20px;
  height: 100%;
  border: none;
}

#services i {
  font-size: 26px;
  margin-bottom: 20px;
}

#services .card:hover i {
  color: #da0303;
}

#services .card:hover {
  box-shadow: 0px 1px 2px #d0d0d0;
}

.card img {
  height: 185px;
  padding-bottom: 10px;
  object-fit: cover;
}

.card p {
  font-size: 16px;
  padding-top: 5px;
  text-align: center;
}

.fontIcon {
  font-size: 26px;
  padding-bottom: 15px;
  color: #b80c0c;
}

#about img {
  padding-right: 30px;
  margin-bottom: 35px;
}

.about-content {
  padding-top: 20px;
}

#menu .nav-link {
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
}

#menu .nav-link.active {
  color: #da0303;
  background-color: transparent;
  border: none;
}

#menu .nav-link:hover,
#menu li.nav-item:hover,
#menu li.nav-item:focus {
  border-color: transparent;
}

.nav-category-menu li {
  width: 100%;
  margin: 0;
}

.nav-category-menu li button {
  border-radius: 15px;
}

.nav-category-menu .nav-link {
  width: 100%;
  padding: 13px 20px;
  text-align: left;
  box-shadow: 0 1px 0px #dfdfdf;
}

#about {
  padding-bottom: 30px;
}

#menu .nav-category-menu .nav-link:hover {
  color: #da0303;
}

#menu .tab-content {
  width: 100%;
}

.navbar-nav-footer {
  margin-left: auto;
  padding-left: 0;
}

.navbar-nav-footer li {
  text-transform: uppercase;
  font-weight: 500;
  padding: 4px;
  list-style: none;
  margin-bottom: 4px;
}

.navbar-nav-footer li a {
  list-style: none;
  color: #dad8d8;
  font-size: 15px;
}

.serviceHead {
  color: #2e2d2d;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
}

.fontAddressIcon {
  padding-right: 15px;
  padding-top: 5px;
}

.phoneNumbers {
  margin-bottom: 4px;
}

.nav-category-menu {
  width: 18%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* Make list vertical */
  align-items: flex-start;
  background-color: #ffffff;
  margin-top: 50px;
  margin-right: 20px;
  /* padding-top: 10px; */
  box-shadow: 5px 5px 5px rgb(235, 234, 234);
}

.nav-item {
  margin-bottom: 10px;
  /* Add space between the items */
}

.tab-container {
  display: flex;
}

.nav-category {
  width: 200px;
  /* Adjust width as needed */
  border-right: 1px solid #ddd;
}

.tab-content-area {
  padding-left: 20px;
  flex: 1;
  /* Take up the remaining space on the right */
}

.navbar-brand img {
  width: 170px;
  /* height: 135px; */
}

#services .card:hover {
  transition: 0.4s;
}

#services .card:hover h5,
#services .card:hover svg {
  color: #da0303;
  cursor: pointer;
}

.social-nav-footer li {
  display: inline-block;
}

.social-nav-footer li svg {
  color: #dad8d8;
  font-size: 24px;
}

.nav-link.phone-no-item,
.menu-header-phone {
  font-size: 18px;
  color: #000;
  /* text-shadow: 0 0 1px #fff; */
}

.reduced-nav #basic-navbar-nav .main-menu.navbar-nav .nav-link.phone-no-item,
.menu-header-phone:hover {
  font-size: 18px;
  color: #da0303;
}

.phone-no-item svg {
  margin-right: 5px;
}

.carousel-caption h3 {
  font-size: 55px;
  font-weight: 700;
}

.carousel-caption p {
  font-size: 20px;
  font-weight: 500;
}

.counter-content {
  /* background-image: url(./images/food-banner.png); */
  background-size: cover;
  padding: 0;
  position: relative;
}

.counter-content img {
  width: 100%;
}

.overlay {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
}

#counter {
  position: relative;
  color: #d54f4f;
  margin-bottom: 100px;
  padding-top: 20px;
}

#counter .col-md-4>div {
  background-color: #fcfcfc;
  /* margin-left: 10px; */
  border-radius: 10px;
  text-align: center;
  /* width: 155px; */
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0px 0px 2px #cccccc;
  height: 100%;
}

#counter .col-md-4 h6 {
  color: #000000;
  font-size: 18px;
}

#counter h4 {
  font-size: 30px;
  font-weight: 700;
}

.banner-btn {
  background-color: #da0303;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px;
  text-shadow: none;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 5px;
  display: inline-block;
}

.banner-btn:hover {
  background-color: #c52020;
}

.counter-data {
  font-size: 50px;
  font-weight: 700;
}

.counter-div {
  color: #fff;
  /* width: 200px; */
  text-align: center;
}

.counter-div h6 {
  font-size: 18px;
  font-weight: 400;
}

footer .nav-link:focus,
/* #basic-navbar-nav .nav-link:focus, */
footer .nav-link:hover,
#basic-navbar-nav .nav-link:hover {
  color: #000;
}

#root {
  overflow: hidden;
}

@media only screen and (max-width: 1350px) {
  #counter-section {
    background-position-x: center;
  }
}

@media only screen and (max-width: 1024px) {
  .main-banner {
    background-position-x: center;
  }

  .carousel-caption h3,
  .carousel-caption p {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media only screen and (max-width: 991px) {
  #menu .nav-link {
    font-size: 14px;
  }

  .nav-category-menu {
    width: 145px;
  }

  #counter-section h3 {
    font-size: 50px;
  }

  #menu-dropdown {
    width: 100%;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: #000;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .navbar-collapse {
    background: rgba(255, 93, 46, 0.9);
  }

  .reduced-nav .navbar-collapse {
    background: #fff;
  }
}

@media only screen and (max-width: 768px) {
  #about img {
    padding-right: 0;
  }

  #home .carousel-caption {
    color: #000000;
    text-shadow: 0px 0px 6px #fff;
  }

  .carousel-caption h3 {
    font-size: 40px;
  }

  #counter-section h3 {
    font-size: 50px;
  }

  .foot-content {
    text-align: center;
  }

  .foot-about-content p {
    max-width: 280px;
    margin: auto;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .contact-data {
    /* padding: 60px 30px; */
    background-color: rgba(255, 255, 255, 0.9);
    /* width: 300px; */
    max-width: 100%;
  }

  #menu .tab-container.mobile-display {
    display: block;
  }

  #menu-dropdown {
    width: 100%;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: #000;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .counter-data {
    font-size: 40px;
  }

  .counter-div {
    margin-bottom: 15px;
  }

  #counter-section h3 {
    font-size: 40px;
  }

  .carousel-caption h3 {
    font-size: 30px;
  }

  .contact-data {
    margin-top: 0;
  }

  .contact-map {
    height: 300px;
  }

  .navbar-collapse {
    background: rgba(255, 93, 46, 0.9);
  }

  .reduced-nav .navbar-collapse {
    background: #fff;
  }
}

/* menu */
#menu-page .menu-main-card img {
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
}

#menu-page .menu-main-card {
  display: block;
  position: relative;
}

.menu-card-title {
  position: absolute;
  top: 25%;
  color: #000;
  padding: 14px 25px 14px 20px;
  background: rgba(255, 255, 255, 1);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.menu-main-card:hover .menu-card-title {
  background-color: #fff;
}

.dotted-div {
  border-bottom: 2px dotted #000;
  width: 95px;
  margin-bottom: 6px;
}

#menu .item-card img {
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}

.social-nav-header li svg {
  color: #000;
}

.menu-header-phone {
  text-decoration: none;
  margin-right: 20px;
}

.menu-header-container {
  border-bottom: 1px solid #ebe7e7;
  padding-bottom: 30px;
  margin-bottom: 40px;
}

.menu-header-whatsapp svg {
  font-size: 23px;
}

.menu-header-whatsapp:hover {
  color: #24c83f;
}

.menu-page-content {
  min-height: calc(100vh - 308px);
}

#menu-page-catogories-tab-head {
  border-radius: 0;
  font-size: 19px;
  background: #272727;
  color: #fff !important;
}

/* #menu-page .menu-main-card img:hover {
  zoom: 1.2;
} */
.footer-whatsapp {
  font-size: 20px;
}